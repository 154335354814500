<template>
  <div id="videos">
    <VideoBanner
      :title="$t('videos.espesanteResist.title')"
      :text="$t('videos.espesanteResist.text')"
      imgUrl="Lata_EspesanteResist.webp"
      videoUrl="https://www.youtube.com/embed/l1X55HozQ-I"
    />

    <VideoBanner
      :title="$t('videos.hangPack.title')"
      :text="$t('videos.hangPack.text')"
      imgUrl="HangPack_JuniorSinLactosa.webp"
      videoUrl="https://www.youtube.com/embed/ZQ6yArNkiew?si=VFpyrpGxi4coBgwy"
    />

    <div class="container links mt-5">
      <h5 class="txt-gris bold">
        {{ $t("recomendaciones.title") }}
      </h5>
      <span>{{ $t("recomendaciones.helper") }}</span>
      <!--  -->
      <!-- NUTRICION -->
      <!--  -->
      <div class="mt-3">
        <h5>{{ $t("recomendaciones.nutricion") }}</h5>
        <div class="wrapper">
          <a href="http://www.sanutricion.org.ar/" target="_blank">
            <div class="box d-flex flex-column">
              <img src="@/assets/images/links/SAN.png" class="img-fluid p-3" />
              <p class="text-center">Sociedad Argentina de Nutrición</p>
            </div>
          </a>
          <a href="http://www.aadynd.org.ar/" target="_blank">
            <div class="box d-flex flex-column">
              <img
                src="@/assets/images/links/AADYND.png"
                class="img-fluid p-3"
              />
              <p class="text-center">
                AADYND: Asociación Argentina de Dietistas y Nutricionistas
                Dietistas
              </p>
            </div>
          </a>
          <a href="http://www.aadynd.org.ar/" target="_blank">
            <div class="box d-flex flex-column">
              <img src="@/assets/images/links/SEN.png" class="img-fluid p-3" />
              <p class="text-center">Sociedad Española de Nutrición</p>
            </div>
          </a>
          <a href="http://www.nutricion.org/" target="_blank">
            <div class="box d-flex flex-column">
              <img
                src="@/assets/images/links/SEDCA.png"
                class="img-fluid p-3"
              />
              <p class="text-center">
                Sociedad española de dietética y ciencias de la alimentación
              </p>
            </div>
          </a>
          <a href="http://www.nutrinfo.com/" target="_blank">
            <div class="box d-flex flex-column">
              <img
                src="@/assets/images/links/nutrinfo.png"
                class="img-fluid p-3"
              />
              <p class="text-center">NUTRINFO</p>
            </div>
          </a>
        </div>
        <hr class="mt-0" />
      </div>
      <!--  -->
      <!-- SOPORTE NUTRICIONAL -->
      <!--  -->
      <div>
        <h5>{{ $t("recomendaciones.soporteNutricional") }}</h5>
        <div class="wrapper">
          <a href="http://www.aanep.com/" target="_blank">
            <div class="box d-flex flex-column">
              <img
                src="@/assets/images/links/AANEP.png"
                class="img-fluid p-3"
              />
              <p class="text-center">
                Asociación Argentina de Nutrición Enteral y Parenteral
              </p>
            </div>
          </a>
          <a href="http://www.nutritioncare.org/" target="_blank">
            <div class="box d-flex flex-column">
              <img
                src="@/assets/images/links/ASPEN.png"
                class="img-fluid p-3"
              />
              <p class="text-center">
                ASPEN – American Society for parenteral and enteral nutrition
              </p>
            </div>
          </a>
          <a href="http://www.espen.org/" target="_blank">
            <div class="box d-flex flex-column">
              <img
                src="@/assets/images/links/ESPEN.png"
                class="img-fluid p-3"
              />
              <p class="text-center">
                ESPEN – European Society for clinical nutrition and metabolism
              </p>
            </div>
          </a>
        </div>
        <hr />
      </div>
      <!--  -->
      <!-- DIABETES -->
      <!--  -->
      <div>
        <h5>{{ $t("recomendaciones.diabetes") }}</h5>
        <div class="wrapper">
          <a href="http://www.diabetes.org/" target="_blank">
            <div class="box d-flex flex-column">
              <img
                src="@/assets/images/links/AANEPADA.png"
                class="img-fluid p-3"
              />
              <p class="text-center">
                American Diabetes Association
              </p>
            </div>
          </a>
          <a href="http://www2.niddk.nih.gov/" target="_blank">
            <div class="box d-flex flex-column">
              <img
                src="@/assets/images/links/niddk.png"
                class="img-fluid p-3"
              />
              <p class="text-center">
                National Institute of Diabetes and Digestive and Kidney Diseases
                – NIDDK
              </p>
            </div>
          </a>
        </div>
        <hr />
      </div>
      <!--  -->
      <!-- RENALES -->
      <!--  -->
      <div>
        <h5>{{ $t("recomendaciones.renales") }}</h5>
        <div class="wrapper">
          <a href="http://www.kidney.org/professionals/kdoqi/" target="_blank">
            <div class="box d-flex flex-column">
              <img src="@/assets/images/links/NKF.png" class="img-fluid p-3" />
              <p class="text-center">National Kidney Foundation</p>
            </div>
          </a>
          <a href="http://www.jrnjournal.org/" target="_blank">
            <div class="box d-flex flex-column">
              <img src="@/assets/images/links/JRN.png" class="img-fluid p-3" />
              <p class="text-center">Journal of Renal Nutrition</p>
            </div>
          </a>
          <a href="http://www.senefro.org/" target="_blank">
            <div class="box d-flex flex-column">
              <img src="@/assets/images/links/SENE.png" class="img-fluid p-3" />
              <p class="text-center">Sociedad española de Nefrología</p>
            </div>
          </a>
        </div>
        <hr />
        <!--  -->
        <!-- ORGANISMOS NACIONALES E INTERNACIONALES -->
        <!--  -->
        <div>
          <h5>{{ $t("recomendaciones.organismos") }}</h5>
          <div class="wrapper">
            <a href="http://www.ilsi.org.ar/" target="_blank">
              <div class="box d-flex flex-column">
                <img
                  src="@/assets/images/links/ilsiargentina.png"
                  class="img-fluid p-3"
                />
                <p class="text-center">ILSI Argentina</p>
              </div>
            </a>
            <a href="http://www.fao.org/index_es.htm" target="_blank">
              <div class="box d-flex flex-column">
                <img
                  src="@/assets/images/links/fao.png"
                  class="img-fluid p-3"
                />
                <p class="text-center">FAO /OMS</p>
              </div>
            </a>
            <a href="https://www.paho.org/es" target="_blank">
              <div class="box d-flex flex-column">
                <img
                  src="@/assets/images/links/ops.png"
                  class="img-fluid p-3"
                />
                <p class="text-center">OPS</p>
              </div>
            </a>
            <a href="http://www.nap.edu/catalog.php?record_id=10490" target="_blank">
              <div class="box d-flex flex-column">
                <img
                  src="@/assets/images/links/NAP.png"
                  class="img-fluid p-3"
                />
                <p class="text-center">National Academies Press</p>
              </div>
            </a>
            <a href="http://www.codexalimentarius.net/web/index_en.jsp" target="_blank">
              <div class="box d-flex flex-column">
                <img
                  src="@/assets/images/links/codex.png"
                  class="img-fluid p-3"
                />
                <p class="text-center">CODEX</p>
              </div>
            </a>
            <a href="http://www.anmat.gov.ar/alimentos/normativas_alimentos_caa.asp" target="_blank">
              <div class="box d-flex flex-column">
                <img
                  src="@/assets/images/links/anmat.png"
                  class="img-fluid p-3"
                />
                <p class="text-center">Codigo Alimentario Argentino</p>
              </div>
            </a>
            <a href="https://iddsi.org/" target="_blank">
              <div class="box d-flex flex-column">
                <img
                  src="@/assets/images/links/iddsi.png"
                  class="img-fluid p-3"
                />
                <p class="text-center">IDDSI</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoBanner from "@/components/VideoBanner";

export default {
  name: "Videos",
  components: {
    VideoBanner,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>