<template>
  <div class="container-fluid bg-gris-claro video-banner">
    <div class="container">
      <div class="row py-5 mt-3">
        <div class="col-md-6 pe-5 ps-0 d-flex flex-column">
          <h5>{{ $t("videos.modoDePreparacion") }}</h5>
          <strong>{{ title }}</strong>
          <p class="pt-2">{{ text }}</p>
          <img :src="getImage(imgUrl)" class="img-fluid h100 lata" />
        </div>
        <div class="col-md-6 p-0">
          <div class="d-flex justify-content-end">
            <iframe
              width="640"
              height="340"
              :src="videoUrl"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoBanner",
  props: {
    title: String,
    text: String,
    imgUrl: String,
    videoUrl: String,
  },
  methods: {
    getImage(fileName) {
      return require("../assets/images/productos/cards/" + fileName);
    },
  },
};
</script>
